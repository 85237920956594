'use client';

import {
  appRouterSsrErrorHandler,
  AppRouterErrorProps,
} from '@highlight-run/next/ssr';
import BaseErrorBoundary from './components/Global/BaseErrorBoundary';

export default appRouterSsrErrorHandler(
  ({ error, reset }: AppRouterErrorProps) => {
    console.error(error);

    return <BaseErrorBoundary callback={reset} />;
  }
);
